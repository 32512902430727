@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body,
	section,
	div,
	a {
		font-family: 'Orbitron', sans-serif;
	}
	#ProjectDescription,
	#AboutParagraph,
	#AboutParagraph a {
		font-family: 'Play', sans-serif;
	}
}

html,
body,
#root,
.app {
	height: 100%;
	overflow-x: hidden;
}
