#title {
	position: relative;
	width: max-content;
}

#title::before,
#title::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

#title::before {
	background: black;
	height: 1.4em;
	animation: typewriter 4s steps(25) 1s forwards;
}

#title::after {
	width: 1em;
	height: 1.3em;
	background-color: white;
	animation: typewriter 4s steps(25) 1s forwards, blink 750ms steps(25) infinite;
}

@keyframes typewriter {
	to {
		left: 100%;
	}
}

@keyframes blink {
	to {
		background: transparent;
	}
}
